exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-advanced-jsx": () => import("./../../../src/pages/advanced.jsx" /* webpackChunkName: "component---src-pages-advanced-jsx" */),
  "component---src-pages-html-5-video-support-jsx": () => import("./../../../src/pages/html5-video-support.jsx" /* webpackChunkName: "component---src-pages-html-5-video-support-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-plugins-jsx": () => import("./../../../src/pages/plugins.jsx" /* webpackChunkName: "component---src-pages-plugins-jsx" */),
  "component---src-templates-blog-list-template-jsx": () => import("./../../../src/templates/BlogListTemplate.jsx" /* webpackChunkName: "component---src-templates-blog-list-template-jsx" */),
  "component---src-templates-blog-post-template-jsx": () => import("./../../../src/templates/BlogPostTemplate.jsx" /* webpackChunkName: "component---src-templates-blog-post-template-jsx" */),
  "component---src-templates-blog-tag-template-jsx": () => import("./../../../src/templates/BlogTagTemplate.jsx" /* webpackChunkName: "component---src-templates-blog-tag-template-jsx" */),
  "component---src-templates-getting-started-template-jsx": () => import("./../../../src/templates/GettingStartedTemplate.jsx" /* webpackChunkName: "component---src-templates-getting-started-template-jsx" */),
  "component---src-templates-guides-list-template-jsx": () => import("./../../../src/templates/GuidesListTemplate.jsx" /* webpackChunkName: "component---src-templates-guides-list-template-jsx" */),
  "component---src-templates-guides-view-template-jsx": () => import("./../../../src/templates/GuidesViewTemplate.jsx" /* webpackChunkName: "component---src-templates-guides-view-template-jsx" */),
  "component---src-templates-home-template-jsx": () => import("./../../../src/templates/HomeTemplate.jsx" /* webpackChunkName: "component---src-templates-home-template-jsx" */)
}

